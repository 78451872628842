import React, {Component} from 'react';

import Container from "react-bootstrap/Container";
import {Button, Col, Image, Row} from "react-bootstrap";

import Footer from "./footer";
import Header from "./header";

class KeyFeatures extends Component {

    constructor(props) {
        super(props);

        this.state ={
            profile: null
        }
    }


    render() {

        return (
            <Container fluid className="p-0" style={{ minHeight: '100vh', backgroundColor: '#0F0F0F' }}>
                <Header/>

                <div style={{
                    padding: '0px 10px', backgroundColor: '#0F0F0F',
                    minHeight: '100vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    backgroundImage: `url('images/features.png')`
                }}
                     className={'text-center align-content-center justify-content-center'}>


                    <span style={{fontSize: '45px', fontWeight: 'bold', color: 'white'}}>
                        Redefining
                        <br/>
Construction Tendering
                    </span>
                    <br/>
                    <span style={{fontSize: '15px', color: 'white'}}>
                        Construction companies spend 2 to 3% of their total revenue bidding on projects. Our AI enabled platform
                        <br/>
automates tedious tasks and provides real-time insights, allowing businesses to operate at peak efficiency,
                        <br/>
                        saving valuable time and resources
                    </span>

                    <br/>

                    <div style={{ marginTop: '20px'}}>
                        <Button style={{borderRadius: 0, background: '#0F0F0F', border: "none", width: '110px'}} className={'m-2'}
                                onClick={this.handleClick}>Try Vect</Button>
                    </div>

                </div>
                <Footer/>
            </Container>

        );
    }

    handleClick = () => {
        window.location.href = '/login';
    };
}

export default KeyFeatures;
