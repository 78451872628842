import React from 'react';

import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Card, Col, Row, Table} from "react-bootstrap";
import Header from "./header";
import Footer from "./footer";
import Container from "react-bootstrap/Container";
import {toast, ToastContainer} from "react-toastify";
import {ReCAPTCHA} from "react-google-recaptcha";



class IndexPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            captchaValue: null,
        };

        this.inputRefs = {
            name: React.createRef(),
            email_address: React.createRef(),
            msg: React.createRef()
        };
        this.recaptchaRef = React.createRef();
    }

    handleCaptchaChange = (value) => {
        console.log('Captcha value:', value);
        this.setState({ captchaValue: value });
    };

    render() {
        return (
            <Container fluid className="p-0" style={{ height: '100vh', overflowX: 'hidden'}}>
                <Header/>
                <ToastContainer/>
                <div style={{minHeight: '100vh'}}>
                    <Row style={{backgroundColor: '#0F0F0F'}} className={'d-flex flex-wrap'}>
                        <Col xs={6} md={6}>
                            <Row>
                                <div style={{
                                    marginLeft: '100px', marginTop: '90px', color: 'white',
                                    fontSize: '40px', fontWeight: 'bold'
                                }}>

                                    Redefining <br/>
                                    Construction <br/>
                                    Tendering

                                </div>
                            </Row>
                            <Row>
                                <div style={{marginLeft: '100px', marginTop: '90px', color: 'white'}}>

                                    Construction companies spend 2 to 3% of their total revenue bidding
                                    on <br/> projects. Our AI
                                    enabled platform automates tedious tasks and provides <br/> real-time insights,
                                    allowing
                                    businesses to operate at peak efficiency, saving <br/> valuable time and resources

                                </div>
                            </Row>
                            <Row>
                                <div style={{marginLeft: '95px', marginTop: '20px'}}>
                                    <Button style={{borderRadius: 0, background: '#0E5B22', border: "none"}}
                                            className={'m-1'} onClick={this.handleClick}>REGISTER
                                        YOUR INTEREST</Button>
                                </div>
                            </Row>
                        </Col>

                        <Col xs={3} md={3}>
                            <div className={'m-5'}>
                                <img src="/images/s1.png" alt="vect AI" height='500'/>
                            </div>
                        </Col>

                    </Row>

                    <Row className={'mt-5 mb-5'}>
                        <Col>
                            <div className={'justify-content-start align-items-start d-flex'}
                                 style={{fontWeight: 'bold', fontSize: '20px', marginLeft: '100px'}}>
                                How We Do It
                            </div>
                        </Col>

                        <Col xs={8}>
                            <div className={'justify-content-start align-items-start d-flex'}
                                 style={{fontSize: '15px'}}>

                                Our solution combines large language models (LLMs) with data retrieval technology.
                                Project information is <br/>
                                uploaded to our platform and crosschecked against our significant catalogue of expert
                                questions. Tailored <br/>
                                question sets, specific to your business, can also be easily added. This will allow you
                                to understand the tender <br/>
                                information in a fraction of the time.

                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <video width="600" controls autoplay>
                            <source src="/video/sampleV.mp4" type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </Row>

                    <Row style={{backgroundColor: '#141414'}}>
                        <Row className={'mt-5 mb-5'}>
                            <Col xs={5}>
                                <div className={'justify-content-start align-items-start d-flex'}
                                     style={{
                                         fontWeight: 'bold',
                                         color: 'white',
                                         fontSize: '20px',
                                         marginLeft: '100px'
                                     }}>
                                    Key Features
                                </div>
                            </Col>

                            <Col xs={7}>
                                <div className={'justify-content-start align-items-start d-flex'}
                                     style={{fontSize: '15px', color: 'white'}}>

                                    With our solution, businesses gain a competitive edge, enhance their decision-making
                                    capabilities, and <br/> successfully navigate the complexities of construction
                                    tendering.

                                </div>
                            </Col>
                        </Row>

                        <Row className={'mt-5 mb-5 d-flex align-items-center justify-content-center flex-wrap'}>
                            <Col xs={12} sm={6} md={3}>
                                <div>
                                    <img src='/images/f1.png' alt='vect AI' width='300'/>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={3}>
                                <div>
                                    <img src='/images/f2.png' alt='vect AI' width='300'/>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={3}>
                                <div>
                                    <img src='/images/f3.png' alt='vect AI' width='300'/>
                                </div>
                            </Col>
                        </Row>

                    </Row>

                    <Row className={'mt-5 mb-5 '}>
                        <Row>
                            <div className={'justify-content-center align-items-center d-flex'}
                                 style={{fontWeight: 'bold', fontSize: '20px'}}>
                                Benefits
                            </div>
                        </Row>

                        <Row className={'d-flex align-items-center justify-content-center flex-wrap p-5'}>
                            <Col xs={12} sm={6} md={3}>
                                <Card style={{backgroundColor: '#0B0B0B'}} text={'light'}
                                      className={'p-1 m-4'}>
                                    <Card.Body>
                                        <Card.Title>Your benefit heading here
                                            will be displayed.</Card.Title>

                                        <Card.Text>
                                            Lorem ipsum dolor sit amet consectetur. Feugiat risus malesuada enim amet id
                                            porttitor consequat viverra. Commodo porttitor at sed et sodales posuere.
                                            porttitor consequat viverra. Commodo porttitor at sed et sodales posuere.
                                        </Card.Text>

                                    </Card.Body>
                                </Card>

                            </Col>
                            <Col xs={12} sm={6} md={3}>
                                <Card style={{backgroundColor: '#0B0B0B'}} text={'light'}
                                      className={'p-1 m-4'}>
                                    <Card.Body>
                                        <Card.Title>Your benefit heading here
                                            will be displayed.</Card.Title>

                                        <Card.Text>
                                            Lorem ipsum dolor sit amet consectetur. Feugiat risus malesuada enim amet id
                                            porttitor consequat viverra. Commodo porttitor at sed et sodales posuere.
                                            porttitor consequat viverra. Commodo porttitor at sed et sodales posuere.
                                        </Card.Text>

                                    </Card.Body>
                                </Card>

                            </Col>
                            <Col xs={12} sm={6} md={3}>
                                <Card style={{backgroundColor: '#0B0B0B'}} text={'light'}
                                      className={'p-1 m-4'}>
                                    <Card.Body>
                                        <Card.Title>Your benefit heading here
                                            will be displayed.</Card.Title>

                                        <Card.Text>
                                            Lorem ipsum dolor sit amet consectetur. Feugiat risus malesuada enim amet id
                                            porttitor consequat viverra. Commodo porttitor at sed et sodales posuere.
                                            porttitor consequat viverra. Commodo porttitor at sed et sodales posuere.
                                        </Card.Text>

                                    </Card.Body>
                                </Card>

                            </Col>
                            <Col xs={12} sm={6} md={3}>
                                <Card style={{backgroundColor: '#0B0B0B'}} text={'light'}
                                      className={'p-1 m-4'}>
                                    <Card.Body>
                                        <Card.Title>Your benefit heading here
                                            will be displayed.</Card.Title>

                                        <Card.Text>
                                            Lorem ipsum dolor sit amet consectetur. Feugiat risus malesuada enim amet id
                                            porttitor consequat viverra. Commodo porttitor at sed et sodales posuere.
                                            porttitor consequat viverra. Commodo porttitor at sed et sodales posuere.
                                        </Card.Text>

                                    </Card.Body>
                                </Card>

                            </Col>
                        </Row>
                    </Row>


                    <Row style={{backgroundColor: '#141414'}}>
                        <Row className={'mt-5 mb-5'}>
                            <Row className={'justify-content-center align-items-center d-flex'}>
                                <div className={'justify-content-center align-items-center d-flex'}
                                     style={{
                                         fontWeight: 'bold',
                                         color: 'white',
                                         fontSize: '20px',
                                         marginLeft: '100px'
                                     }}>
                                    Request A Call
                                </div>
                            </Row>
                            <Row className={'justify-content-center align-items-center d-flex'}>
                                <div className={'justify-content-center align-items-center d-flex w-50'}
                                     style={{
                                         color: 'white',
                                         fontSize: '15px',
                                         marginLeft: '100px',
                                         textAlign: 'center'
                                     }}>
                                    With our solution, businesses gain a competitive edge, enhance their decision-making
                                    capabilities, and successfully navigate the complexities of construction tendering.
                                </div>
                            </Row>

                        </Row>

                        <Row className={'mt-5 mb-5 d-flex align-items-center justify-content-center'}>

                            <Table size="sm" style={{border: 'none', background: 'none', width: '58rem'}}>

                                <tbody style={{background: 'none'}}>

                                <tr>
                                    <td style={{border: 'none', backgroundColor: 'transparent'}}>
                                        <input type='text' placeholder='Full Name*' required={true} className={'p-3'}
                                               ref={this.inputRefs.name}
                                               style={{
                                                   color: 'white',
                                                   background: '#D9D9D91A',
                                                   border: 0,
                                                   width: '100%',
                                                   height: '3.1rem'
                                               }}/>
                                    </td>
                                    <td style={{border: 'none', backgroundColor: 'transparent'}}>
                                        <input type='email' placeholder='Email Address*' required={true}
                                               className={'p-3'}
                                               ref={this.inputRefs.email_address}
                                               style={{
                                                   color: 'white',
                                                   background: '#D9D9D91A',
                                                   border: 0,
                                                   width: '100%',
                                                   height: '3.1rem'
                                               }}/>
                                    </td>

                                </tr>
                                <tr>
                                    <td colSpan={2} style={{border: 'none', backgroundColor: 'transparent'}}>

                                    <textarea placeholder='Your Message (Optional)' required={false} className={'p-3'}
                                              ref={this.inputRefs.msg}
                                              style={{
                                                  color: 'white',
                                                  background: '#D9D9D91A',
                                                  border: 0,
                                                  width: '100%',
                                                  height: '8.1rem'
                                              }}/>

                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{border: 'none', backgroundColor: 'transparent'}}>
                                        <div className={'justify-content-center d-flex'}>
                                            <Button
                                                onClick={(e) => this.handleSubmit(e)}
                                                style={{
                                                    borderRadius: 0,
                                                    background: '#0E5B22',
                                                    border: "none",
                                                    width: '60%'
                                                }}>Submit</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{border: 'none', backgroundColor: 'transparent'}}>
                                        <div className={'justify-content-center d-flex'}>
                                            <ReCAPTCHA
                                                ref={this.recaptchaRef}
                                                sitekey="YOUR_SITE_KEY" // Replace with your actual site key
                                                onChange={this.handleCaptchaChange}
                                            />
                                        </div>
                                    </td>
                                </tr>

                                </tbody>
                            </Table>

                        </Row>

                    </Row>
                </div>
                <Footer/>

            </Container>
        );
    }

    handleClick = () => {
        window.location.href = '/login';
    };

    handleSubmit = (event) => {
        // Access the value of the firstname input field
        event.preventDefault();

        // if (!this.state.captchaValue) {
        //     toast.error('Please verify that you are a human!', {position: 'top-left'});
        //     return;
        // }

        const name = this.inputRefs.name.current.value;
        const emailValue = this.inputRefs.email_address.current.value;
        const msg = this.inputRefs.msg.current.value;

        if (name === null || emailValue === null || msg === null) {
            toast.error('Oops! Looks like you missed a field. Please double-check all fields.', {
                position: 'top-left'
            });

        }else {


            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    name: name,
                    email: emailValue,
                    msg: msg
                })
            };

            fetch('http://45.76.142.28/api/v1/contact-us/', requestOptions)
                .then((response) => {
                    if (!response.ok) throw response.status;
                    else return response.json();
                })
                .then((response) => {
                    if(response.status === 201) {
                        toast.info('Thank you for reaching out to us. We will get back to you shortly.',
                            {position: 'top-left'});
                        this.recaptchaRef.current.reset();
                    }
                })
                .catch((error) => {

                    if(error === 429) {
                        toast.error('You\'ve hit the limit for requests. Please wait 10 minutes before trying again.', {
                            position: 'top-left'
                        });
                        this.recaptchaRef.current.reset();
                    }else {
                        toast.error('Oops! Looks like you missed a field. Please double-check all fields.', {
                            position: 'top-left'
                        });
                        this.recaptchaRef.current.reset();
                    }

                });

        }
    }
}


function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()}/>;
}

export default withParams(IndexPage);
