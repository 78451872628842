import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {Button} from "react-bootstrap";


class UserProfileHeader extends Component{

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            page_name: props.page
        }
    }


    render(){
        return(
            <Navbar style={{backgroundColor: '#171717'}} expand="lg" >
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            src="/logo192.png"
                            height="30"
                            className="d-inline-block align-top"
                            alt="Vect AI "
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className={"justify-content-end"}>
                        <Nav >

                            <div style={{marginLeft: '90px'}}>
                                <Button  style={{borderRadius: 0, background: '#0E5B22', border: "none"}} className={'m-1'}
                                         onClick={(v) => this.handleClick(v)}>Back to Dashboard</Button>
                            </div>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }

    handleClick(e) {
        window.location.href = '/panel';
    }
}

export default UserProfileHeader;