import './App.css';
import {Route, Routes} from "react-router";
import IndexPage from "./components";
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./components/login";
import Register from "./components/register";
import Panel from "./components/panel/panel";
import Projects from "./components/panel/projects";
import QueryTool from "./components/panel/query_tool";
import QueryToolChat from "./components/panel/query_tool_chat";
import AddProject from "./components/panel/add_project";
import "react-toastify/dist/ReactToastify.css";
import FAQ from "./components/panel/faq";
import Privacy from "./components/panel/privacy";
import UserGuide from "./components/panel/user_guide";
import TechnicalQuery from "./components/panel/technical_query";
import TechnicalQueryChat from "./components/panel/technical_query_chat";
import UserProfile from "./components/panel/user_profile";
import AboutUS from "./components/about_us";
import KeyFeatures from "./components/key_features";
import ForgetPassword from "./components/forget_password";

function App() {
  return (
      <Routes>
        <Route path='/' element={<IndexPage />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/panel' element={<Panel />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/adding-new-project' element={<AddProject />} />
        <Route path='/query-tool' element={<QueryTool />} />
        <Route path='/query-tool-chat' element={<QueryToolChat />} />
        <Route path='/faq' element={<FAQ />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/user-guideline' element={<UserGuide />} />
        <Route path='/technical-query' element={<TechnicalQuery />} />
        <Route path='/technical-query-chat' element={<TechnicalQueryChat />} />
        <Route path='/user-profile' element={<UserProfile />} />
        <Route path='/about-us' element={<AboutUS />} />
        <Route path='/features' element={<KeyFeatures />} />
        <Route path='/reset-password' element={<ForgetPassword />} />
      </Routes>
  );
}

export default App;
