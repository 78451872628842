import React, { Component } from 'react';
import SideNavbar from "./side_navbar";
import Container from "react-bootstrap/Container";
import {Button, Card, Col, Image, Row, Table} from "react-bootstrap";
import {Avatar} from "@mui/material";

class Panel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: null,
            projects: null,
            projects_count: 0,
            query_count: 0
        }
    }
    stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    stringAvatar(name) {
        return {
            sx: {
                bgcolor: this.stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            window.location.href = "/login";
        }else {
            this.getUserProfile()
            this.getProjectsList()
        }
    }

    getUserProfile(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('http://45.76.142.28/api/v1/profile/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {

                console.log(response);
                this.setState({profile: response});


            })
            .catch((error) => {
                localStorage.removeItem('token');
                window.location.href = '/login';

            });
    }

    getProjectsList(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('http://45.76.142.28/api/v1/dashboard-data/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {

                this.setState({
                    projects: response.projects,
                    projects_count: response.projects_count,
                    query_count: response.query_count
                });


            })
            .catch((error) => {
                localStorage.removeItem('token');
                window.location.href = '/login';

            });
    }

    render() {
        if(this.state.profile === null) {
            return (
                <div>
                    Loading...
                </div>
            )
        }
        return (
            <Container fluid className="p-0" style={{ minHeight: '100vh', backgroundColor: '#0F0F0F' }}>
                <SideNavbar
                    page_name={'Dashboard'}
                />

                <div style={{ marginLeft: '300px', padding: '0px 10px', backgroundColor: '#0F0F0F', minHeight: '100vh' }}>
                    <Row>
                        <Col>
                            <div className="justify-content-start align-items-start mt-4"
                                 style={{ fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                                Your Dashboard
                            </div>
                        </Col>
                        <Col>
                            <div className="justify-content-end align-items-end d-flex m-3">
                                <span className={'mb-2'} style={{color: 'white', marginRight: '10px'}}>{this.state.profile.first_name + ' ' + this.state.profile.last_name}</span>
                                <Avatar {...this.stringAvatar(this.state.profile.first_name + ' ' + this.state.profile.last_name)} />

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={"auto"}>
                            <Card style={{width: '20rem', height: '10rem', backgroundColor: '#171717'}}>
                                <Card.Body>
                                    <div className="justify-content-start align-items-start m-1"
                                         style={{fontWeight: 'bold', fontSize: '20px', color: 'white'}}>
                                        Total Added Projects
                                    </div>
                                    <div className="justify-content-end align-items-end d-flex m-1">
                                        <Image
                                            src="images/card_d1.png"
                                            fluid
                                            className="d-inline-block align-top"
                                            alt="Vect AI "
                                            style={{ width: '100px' }}
                                        />
                                    </div>
                                    <div className="justify-content-start align-items-start m-1"
                                         style={{fontWeight: 'bold', fontSize: '20px', color: 'white'}}>
                                        {this.state.projects_count}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={"auto"}>
                            <Card style={{width: '20rem', height: '10rem', backgroundColor: '#171717'}}>
                                <Card.Body>
                                    <div className="justify-content-start align-items-start m-1"
                                         style={{fontWeight: 'bold', fontSize: '20px', color: 'white'}}>
                                        Query Tool Scenario Made
                                    </div>
                                    <div className="justify-content-end align-items-end d-flex m-1">
                                        <Image
                                            src="images/card_d1.png"
                                            fluid
                                            className="d-inline-block align-top"
                                            alt="Vect AI "
                                            style={{ width: '100px' }}
                                        />
                                    </div>
                                    <div className="justify-content-start align-items-start m-1"
                                         style={{fontWeight: 'bold', fontSize: '20px', color: 'white'}}>
                                        {this.state.query_count}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={"auto"}>
                            <Card style={{width: '20rem', height: '10rem', backgroundColor: '#0E5B22'}}>
                                <Card.Body>
                                    <div
                                        className="d-flex flex-column justify-content-end m-1"
                                        style={{
                                            fontWeight: 'bold',
                                            textDecoration: 'underline',
                                            fontSize: '20px',
                                            color: 'white',
                                            height: '100%',
                                            cursor: 'pointer'  // Change cursor to hand
                                        }}
                                        onClick={(e) => {
                                            // Your click handler code here
                                            window.location.href = '/adding-new-project'
                                        }}
                                    >
                                        + Add Project
                                    </div>


                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className={'mt-5'}>
                        <div className="justify-content-start align-items-start mt-4"
                             style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                            Recent Projects
                        </div>
                    </Row>
                    <Row>
                        {this.getPreviousProjects()}
                    </Row>
                </div>
            </Container>

        );
    }

    getPreviousProjects() {
        if(this.state.projects === null || this.state.projects.length === 0) {
            return (
                <div className={'text-center justify-content-center align-items-center d-flex mt-5'}
                     style={{color: 'white', fontSize: '13px'}}>
                    You don’t have any projects yet. Start by creating your first project!
                </div>
            )
        }


        return (
            <Table responsive className={'mt-4'} variant="dark">

                <tbody>
                {this.state.projects.map((item, index) => (
                    <tr className={'w-100'}>
                        <td style={{verticalAlign: 'middle', border: 'none', backgroundColor: 'transparent'}}>
                            <Image
                                src="images/pr.png"
                                fluid
                                className="d-inline-block align-top"
                                alt="Vect AI "
                                style={{width: '30'}}
                            />
                        </td>
                        <td style={{verticalAlign: 'middle', border: 'none', backgroundColor: 'transparent'}}>
                            {item.name}
                        </td>
                        <td style={{verticalAlign: 'middle', border: 'none', backgroundColor: 'transparent'}}>
                            {item.create_at}
                        </td>
                        <td style={{verticalAlign: 'middle', border: 'none', backgroundColor: 'transparent'}}>
                            <div>
                                <Button style={{borderRadius: 0, background: '#0E5B22', border: "none"}}
                                        className={'m-1'} onClick={(e) => this.handleClick(e, item.id)}>View Details</Button>
                            </div>
                        </td>
                    </tr>
                ))}

                </tbody>
            </Table>
        )

    }

    handleClick(e, id){
        window.location.href = '/query-tool-chat?id=' + id;
    }
}

export default Panel;
