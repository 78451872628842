import React, {Component} from 'react';

import Container from "react-bootstrap/Container";
import {Col, Image, Row} from "react-bootstrap";

import Footer from "./footer";
import Header from "./header";

class AboutUS extends Component {

    constructor(props) {
        super(props);

        this.state ={
            profile: null
        }
    }


    render() {

        return (
            <Container fluid className="p-0 pb-5" style={{ minHeight: '100vh', backgroundColor: '#0F0F0F' }}>
                <Header/>

                <div style={{ padding: '0px 10px', backgroundColor: '#0F0F0F', minHeight: '100vh' }}>


                        <Image
                            src="images/userguide.png"
                            fluid
                            className="d-inline-block align-top"
                            alt="Vect AI "
                            style={{width: '100%'}}
                        />



                </div>
                <Footer/>
            </Container>

        );
    }
}

export default AboutUS;
