import React, { Component } from 'react';
import SideNavbar from "./side_navbar";
import Container from "react-bootstrap/Container";
import {Button, Card, Col, Row} from "react-bootstrap";
import {Avatar} from "@mui/material";

class Projects extends Component {

    constructor(props) {
        super(props);

        this.state ={
            projects: null,
            profile: null
        }
    }

    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            window.href.location = "/login";
        }

        this.getUserProfile()
        this.getProjectsList()
    }

    getUserProfile(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('http://45.76.142.28/api/v1/profile/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {

                this.setState({profile: response});


            })
            .catch((error) => {
                localStorage.removeItem('token');
                window.location.href = '/login';

            });
    }

    stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    stringAvatar(name) {
        return {
            sx: {
                bgcolor: this.stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    getProjectsList(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('http://45.76.142.28/api/v1/projects/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {

                this.setState({projects: response});


            })
            .catch((error) => {
                localStorage.removeItem('token');
                window.location.href = '/login';

            });
    }

    render() {
        if(this.state.projects === null || this.state.projects.length === 0) {
            return (
                <div className={'text-center justify-content-center align-items-center d-flex mt-5'}
                     style={{color: 'white', fontSize: '13px'}}>
                    You don’t have any projects yet. Start by creating your first project!
                </div>
            )
        }
        return (
            <Container fluid className="p-0 pb-5" style={{ minHeight: '100vh', backgroundColor: '#0F0F0F' }}>
                <SideNavbar
                    page_name={'Projects'}
                />

                <div style={{ marginLeft: '300px', padding: '0px 10px', backgroundColor: '#0F0F0F', minHeight: '100vh' }}>
                    <Row>
                        <Col>
                            <div className="justify-content-start align-items-start mt-4"
                                 style={{ fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                                Your Dashboard
                            </div>
                        </Col>
                        <Col>
                            <div className="justify-content-end align-items-end d-flex m-3">
                                <span className={'mb-2'} style={{
                                    color: 'white',
                                    marginRight: '10px'
                                }}>{this.state.profile.first_name + ' ' + this.state.profile.last_name}</span>
                                <Avatar {...this.stringAvatar(this.state.profile.first_name + ' ' + this.state.profile.last_name)} />

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={"auto"}>

                            <Button onClick={() => {
                                window.location.href = '/adding-new-project'
                            }} style={{background: 'none', borderColor: '#0E5B22', borderRadius: '0'}}>+ Add New Project</Button>
                        </Col>

                    </Row>

                    <Row className={'mt-5'}>
                        <div className="justify-content-start align-items-start mt-4"
                             style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                            Previous Projects
                        </div>
                    </Row>
                    <Row >
                        {this.state.projects.map((item, index) => (
                            <Col xs={'auto'}>
                                <Card style={{ width: '18rem', backgroundColor: 'transparent' }} className={'mt-4'}>
                                    <div className={'text-center'}>
                                        <Card.Img variant="center" src="images/ppl.png"
                                                  style={{width: '40%'}}
                                        />
                                    </div>
                                    <Card.Body style={{ backgroundColor: '#1F1F1F' }}>
                                        <a href={`/query-tool-chat?id=${item.id}`} style={{ textDecoration: 'none' }}><Card.Text
                                            style={{color: 'white', fontWeight: 'bold', fontSize: '15px'}}
                                            className={'p-0 m-0'}>{item.name}</Card.Text>
                                        </a>
                                        <Card.Text style={{color: 'white', fontSize: '15px'}} className={'p-0 m-0'} >{item.create_at}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}

                    </Row>
                </div>
            </Container>

        );
    }
}

export default Projects;
