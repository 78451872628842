import React from 'react';
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import {Button} from "react-bootstrap";

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            page_name: props.page
        }
    }

  render() {
    return (
        <Navbar style={{backgroundColor: '#171717'}} expand="lg" >
            <Container>
                <Navbar.Brand href="/">
                    <img
                        src="/logo192.png"
                        height="30"
                        className="d-inline-block align-top"
                        alt="Vect AI "
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className={"justify-content-end"}>
                    <Nav >

                        <Nav.Link href="/" style={{color: 'white'}}>Home</Nav.Link>
                        <Nav.Link href="/about-us" style={{color: 'white'}}>About Us</Nav.Link>
                        <Nav.Link href="/features" style={{color: 'white'}}>Key Features</Nav.Link>
                        {/*<Nav.Link href="/teams" style={{color: 'white'}}>Our Team</Nav.Link>*/}
                        <div style={{marginLeft: '90px'}}>
                            <Button  style={{borderRadius: 0, background: '#0E5B22', border: "none"}} className={'m-1'} onClick={this.handleClick}>REGISTER YOUR INTEREST</Button>
                        </div>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
  }

    handleClick = () => {
        window.location.href = '/login';
    };
}

export default Header;