import React, { Component } from 'react';
import SideNavbar from "./side_navbar";
import Container from "react-bootstrap/Container";
import {Col, Image, Row} from "react-bootstrap";
import {Avatar} from "@mui/material";
import {FaArrowLeftLong} from "react-icons/fa6";
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import styled from './form_placeholder.css';
import './overlay.css';
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Conversation,
    Message, InputToolbox, TypingIndicator
} from '@chatscope/chat-ui-kit-react';
import {PiNote} from "react-icons/pi";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import {toast, ToastContainer} from "react-toastify";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

class TechnicalQueryChat extends Component {

    constructor(propse) {
        super(propse);

        this.state = {
            data: null,
            completions: null,
            intervalId: null,
            is_waiting: false,
            last_prompt: null,
            profile: null
        }

        this.intervalDuration = 5000;
    }

    componentDidMount(){
        if (localStorage.getItem("token") == null) {
            window.href.location = "/login";
        }

        this.getData()
        this.getUserProfile()
    }

    getUserProfile(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('http://45.76.142.28/api/v1/profile/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {

                this.setState({profile: response});


            })
            .catch((error) => {
                localStorage.removeItem('token');
                window.location.href = '/login';

            });
    }

    getData(){
        const urlParams = new URLSearchParams(window.location.search);
        const project_id = urlParams.get('id');


        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch(`http://45.76.142.28/api/v1/project-tq?id=${project_id}`, requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {

                this.setState({data: response, completions: response.chat_histories});

            })
            .catch((error) => {
                localStorage.removeItem('token');
                window.location.href = '/login';

            });
    }

    stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    stringAvatar(name) {
        return {
            sx: {
                bgcolor: this.stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    render() {
        if(this.state.data == null){
            return (
                <div>
                    Loading...
                </div>
            )
        }

        if(this.state.is_waiting){
            return (
                <Container fluid className="p-0" style={{ minHeight: '100vh', backgroundColor: '#0F0F0F' }}>
                    <ToastContainer/>
                    <SideNavbar
                        page_name={'TechnicalQuery'}
                    />

                    <div style={{ marginLeft: '290px', padding: '0px 10px', backgroundColor: '#0F0F0F', minHeight: '100vh' }}>
                        <Row>
                            <Col>
                                <div className="justify-content-start align-items-start mt-4"
                                     style={{ fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                                    Your Dashboard
                                </div>
                            </Col>
                            <Col>
                                <div className="justify-content-end align-items-end d-flex m-3">
                                    <span className={'mb-2'} style={{
                                        color: 'white',
                                        marginRight: '10px'
                                    }}>{this.state.profile.first_name + ' ' + this.state.profile.last_name}</span>
                                    <Avatar {...this.stringAvatar(this.state.profile.first_name + ' ' + this.state.profile.last_name)} />
                                </div>
                            </Col>
                        </Row>

                        <Row className={'mt-1'}>
                            <Col md={4}>
                                <div className="justify-content-start align-items-start mt-1"
                                     style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                                    Query History
                                </div>
                            </Col>
                            <Col md={{ span: 4, offset: 4 }} className={'text-end'}>
                                <div className="justify-content-end align-items-end mt-1"
                                     onClick={() => {
                                         // Your click handler code here
                                         window.location.href = '/projects'
                                     }}
                                     style={{fontWeight: 'bold',
                                         fontSize: '13px',
                                         marginRight: '20px',
                                         color: 'white',  cursor: 'pointer'}}>
                                    <FaArrowLeftLong /> <span style={{marginLeft: '10px'}} className={'text-end'}> Back To Previous Page</span>

                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <div style={{ position: "relative" }}>
                                <MainContainer style={{height: '100vh', background: '#171717', borderColor: '#171717'}} responsive className={'p-2'}>
                                    {/*<Sidebar position="left" scrollable={true} >*/}

                                    {/*    /!*<ConversationList style={{background: '#171717'}}>*!/*/}
                                    {/*    /!*    {this.state.data.query_history.map((item, index) => {*!/*/}
                                    {/*    /!*        if (Object.getOwnPropertyNames(item).length > 0) {*!/*/}
                                    {/*    /!*            return this.getChatroomListDesktop(index, item);*!/*/}
                                    {/*    /!*        }*!/*/}
                                    {/*    /!*    })}*!/*/}


                                    {/*    /!*    <div style={{width: '100%', height: '1px', background: '#919191'}}></div>*!/*/}

                                    {/*    /!*</ConversationList>*!/*/}
                                    {/*</Sidebar>*/}

                                    <ChatContainer style={{background: '#171717'}}>
                                        <MessageList style={{background: '#171717'}}>
                                            {this.state.completions.map((item, index) => (
                                                this.getChatRoomDesktopMsgList(index, item)
                                            ))}
                                        </MessageList>

                                        <InputToolbox style={{background: '#171717'}}
                                                      className={'text-start d-flex justify-content-start '}>

                                            <div className={'w-100'}>
                                                <Row>

                                                    <Col xs={'auto'}
                                                         style={{
                                                             border: '2px solid #0E5B22',
                                                             padding: '10px', marginLeft: '40px'
                                                         }}>
                                                        <div style={{borderColor: '#0E5B22', color: 'white'}}>
                                                            Project Name: {this.state.data.project_name}
                                                        </div>
                                                    </Col>

                                                    <Col xs={'auto'} style={{
                                                        border: '2px solid #0E5B22',
                                                        padding: '10px', marginLeft: '20px'
                                                    }}>
                                                        <div style={{borderColor: '#0E5B22', color: 'white'}}>
                                                            Total Uploaded Files: {this.state.data.number_of_pdfs}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className={'mt-4'} style={{
                                                    marginLeft: '30px',
                                                    background: 'transparent', border: '2px solid #0E5B22',
                                                    position: 'relative',
                                                    pointerEvents: 'none',
                                                    opacity: '0.5'
                                                }}>
                                                    <div
                                                        className={' overlay text-center d-flex justify-content-center align-items-center'}>
                                                        <TypingIndicator style={{color: '#0E5B22', fontSize: '13px'}} content="processing"/>
                                                    </div>
                                                    <Col md={'11'}>
                                                        <input placeholder={this.state.last_prompt}
                                                               onKeyDown={(e) => {
                                                                   if (e.key === "Enter") {
                                                                       this.sendPrompt(e)
                                                                   }
                                                               }}
                                                               style={{
                                                                   outline: 'none',
                                                                   border: 'none',
                                                                   width: '100%',
                                                                   color: 'white',
                                                                   background: '#171717', height: '50px'
                                                               }}/>
                                                    </Col>
                                                    <Col md={1}
                                                         className={'d-flex justify-content-center align-items-center'}>
                                                        <HiOutlineArrowLongRight
                                                            onClick={(e) => this.sendPrompt(e)}
                                                            style={{
                                                                color: 'white',
                                                                background: 'green',
                                                                width: '30px',
                                                                cursor: 'pointer',
                                                                height: '30px'
                                                            }}/>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </InputToolbox>


                                    </ChatContainer>

                                </MainContainer>
                            </div>

                        </Row>


                    </div>
                </Container>

            );
        }

        if(this.state.completions === null) {
            return (
                <Container fluid className="p-0" style={{minHeight: '100vh', backgroundColor: '#0F0F0F'}}>
                    <ToastContainer/>
                    <SideNavbar
                        page_name={'TechnicalQuery'}
                    />

                    <div style={{
                        marginLeft: '290px',
                        padding: '0px 10px',
                        backgroundColor: '#0F0F0F',
                        minHeight: '100vh'
                    }}>
                        <Row>
                            <Col>
                                <div className="justify-content-start align-items-start mt-4"
                                     style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                                    Your Dashboard
                                </div>
                            </Col>
                            <Col>
                                <div className="justify-content-end align-items-end d-flex m-3">
                                    <span className={'mb-2'} style={{
                                        color: 'white',
                                        marginRight: '10px'
                                    }}>{this.state.profile.first_name + ' ' + this.state.profile.last_name}</span>
                                    <Avatar {...this.stringAvatar(this.state.profile.first_name + ' ' + this.state.profile.last_name)} />
                                </div>
                            </Col>
                        </Row>

                        <Row className={'mt-1'}>
                            <Col md={4}>
                                <div className="justify-content-start align-items-start mt-1"
                                     style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                                    Query History
                                </div>
                            </Col>
                            <Col md={{span: 4, offset: 4}} className={'text-end'}>
                                <div className="justify-content-end align-items-end mt-1"
                                     onClick={() => {
                                         // Your click handler code here
                                         window.location.href = '/projects'
                                     }}
                                     style={{
                                         fontWeight: 'bold',
                                         fontSize: '13px',
                                         marginRight: '20px',
                                         color: 'white', cursor: 'pointer'
                                     }}>
                                    <FaArrowLeftLong/> <span style={{marginLeft: '10px'}} className={'text-end'}> Back To Previous Page</span>

                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <div style={{position: "relative"}}>
                                <MainContainer style={{height: '100vh', background: '#171717', borderColor: '#171717'}}
                                               responsive className={'p-2'}>
                                    {/*<Sidebar position="left" scrollable={true}>*/}

                                    {/*    /!*<ConversationList style={{background: '#171717'}}>*!/*/}
                                    {/*    /!*    {this.state.data.query_history.map((item, index) => {*!/*/}
                                    {/*    /!*        if (Object.getOwnPropertyNames(item).length > 0) {*!/*/}
                                    {/*    /!*            return this.getChatroomListDesktop(index, item);*!/*/}
                                    {/*    /!*        }*!/*/}
                                    {/*    /!*    })}*!/*/}


                                    {/*    /!*    <div style={{width: '100%', height: '1px', background: '#919191'}}></div>*!/*/}

                                    {/*    /!*</ConversationList>*!/*/}
                                    {/*</Sidebar>*/}

                                    <ChatContainer style={{background: '#171717'}}>
                                        <MessageList style={{background: '#171717'}}>
                                            <MessageList.Content style={{
                                                display: "flex",
                                                "flexDirection": "column",
                                                "justifyContent": "center",
                                                height: "100%",
                                                textAlign: "center",
                                                fontSize: "1.2em",
                                                marginLeft: '16px'
                                            }}>
                                                <Image
                                                    src="/logo192.png"
                                                    fluid
                                                    className="d-inline-block align-top"
                                                    alt="Vect AI "
                                                    style={{width: '90px'}}
                                                />
                                                <div className={'text-start mt-4'}
                                                     style={{
                                                         fontSize: '15px',
                                                         color: '#5A5C5F'
                                                     }}>
                                                    Guideline for query tool
                                                </div>

                                            </MessageList.Content>

                                        </MessageList>

                                        <InputToolbox style={{background: '#171717'}}
                                                      className={'text-start d-flex justify-content-start '}>

                                            <div className={'w-100'}>
                                                <Row>

                                                    <Col xs={'auto'}
                                                         style={{
                                                             border: '2px solid #0E5B22',
                                                             padding: '10px', marginLeft: '40px'
                                                         }}>
                                                        <div style={{borderColor: '#0E5B22', color: 'white'}}>
                                                            Project Name: {this.state.data.project_name}
                                                        </div>
                                                    </Col>

                                                    <Col xs={'auto'} style={{
                                                        border: '2px solid #0E5B22',
                                                        padding: '10px', marginLeft: '20px'
                                                    }}>
                                                        <div style={{borderColor: '#0E5B22', color: 'white'}}>
                                                            Total Uploaded Files: {this.state.data.number_of_pdfs}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className={'mt-4'} style={{
                                                    marginLeft: '30px',
                                                    background: 'transparent', border: '2px solid #0E5B22'
                                                }}>
                                                    <Col md={'11'}>
                                                        <input placeholder={'Write your query here'}
                                                               id={'prompt_input'}
                                                               onKeyDown={(e) => {
                                                                   if (e.key === "Enter") {
                                                                       this.sendPrompt(e)
                                                                   }
                                                               }}
                                                               style={{
                                                                   outline: 'none',
                                                                   border: 'none',
                                                                   width: '100%',
                                                                   color: 'white',
                                                                   background: '#171717', height: '50px'
                                                               }}/>
                                                    </Col>
                                                    <Col md={1}
                                                         className={'d-flex justify-content-center align-items-center'}>
                                                        <HiOutlineArrowLongRight
                                                            onClick={(e) => this.sendPrompt(e)}
                                                            style={{
                                                                color: 'white',
                                                                background: 'green',
                                                                width: '30px',
                                                                cursor: 'pointer',
                                                                height: '30px'
                                                            }}/>
                                                    </Col>
                                                </Row>


                                            </div>
                                        </InputToolbox>


                                    </ChatContainer>

                                </MainContainer>
                            </div>

                        </Row>


                    </div>
                </Container>

            );
        }

        return (
            <Container fluid className="p-0" style={{minHeight: '100vh', backgroundColor: '#0F0F0F'}}>
                <ToastContainer/>
                <SideNavbar
                    page_name={'TechnicalQuery'}
                />

                <div style={{
                    marginLeft: '290px',
                    padding: '0px 10px',
                    backgroundColor: '#0F0F0F',
                    minHeight: '100vh'
                }}>
                    <Row>
                        <Col>
                            <div className="justify-content-start align-items-start mt-4"
                                 style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                                Your Dashboard
                            </div>
                        </Col>
                        <Col>
                            <div className="justify-content-end align-items-end d-flex m-3">
                                <span className={'mb-2'} style={{
                                    color: 'white',
                                    marginRight: '10px'
                                }}>{this.state.profile.first_name + ' ' + this.state.profile.last_name}</span>
                                <Avatar {...this.stringAvatar(this.state.profile.first_name + ' ' + this.state.profile.last_name)} />
                            </div>
                        </Col>
                    </Row>

                    <Row className={'mt-1'}>
                        <Col md={4}>
                            <div className="justify-content-start align-items-start mt-1"
                                 style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                                Query History
                            </div>
                        </Col>
                        <Col md={{span: 4, offset: 4}} className={'text-end'}>
                            <div className="justify-content-end align-items-end mt-1"
                                 onClick={() => {
                                     // Your click handler code here
                                     window.location.href = '/projects'
                                 }}
                                 style={{
                                     fontWeight: 'bold',
                                     fontSize: '13px',
                                     marginRight: '20px',
                                     color: 'white', cursor: 'pointer'
                                 }}>
                                <FaArrowLeftLong/> <span style={{marginLeft: '10px'}} className={'text-end'}> Back To Previous Page</span>

                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <div style={{position: "relative"}}>
                            <MainContainer style={{height: '100vh', background: '#171717', borderColor: '#171717'}}
                                           responsive className={'p-2'}>
                                {/*<Sidebar position="left" scrollable={true}>*/}

                                {/*    /!*<ConversationList style={{background: '#171717'}}>*!/*/}
                                {/*    /!*    {this.state.data.query_history.map((item, index) => {*!/*/}
                                {/*    /!*        if (Object.getOwnPropertyNames(item).length > 0) {*!/*/}
                                {/*    /!*            return this.getChatroomListDesktop(index, item);*!/*/}
                                {/*    /!*        }*!/*/}
                                {/*    /!*    })}*!/*/}


                                {/*    /!*    <div style={{width: '100%', height: '1px', background: '#919191'}}></div>*!/*/}

                                {/*    /!*</ConversationList>*!/*/}
                                {/*</Sidebar>*/}

                                <ChatContainer style={{background: '#171717'}} >
                                    <MessageList style={{background: '#171717'}}>
                                        {this.state.completions.map((item, index) => (
                                            this.getChatRoomDesktopMsgList(index, item)
                                        ))}
                                    </MessageList>

                                    <InputToolbox style={{background: '#171717'}}
                                                  className={'text-start d-flex justify-content-start '}>

                                        <div className={'w-100'}>
                                            <Row>

                                                <Col xs={'auto'}
                                                     style={{
                                                         border: '2px solid #0E5B22',
                                                         padding: '10px', marginLeft: '40px'
                                                     }}>
                                                    <div style={{borderColor: '#0E5B22', color: 'white'}}>
                                                        Project Name: {this.state.data.project_name}
                                                    </div>
                                                </Col>

                                                <Col xs={'auto'} style={{
                                                    border: '2px solid #0E5B22',
                                                    padding: '10px', marginLeft: '20px'
                                                }}>
                                                    <div style={{borderColor: '#0E5B22', color: 'white'}}>
                                                        Total Uploaded Files: {this.state.data.number_of_pdfs}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className={'mt-4'} style={{
                                                marginLeft: '30px',
                                                background: 'transparent', border: '2px solid #0E5B22'
                                            }}>
                                                <Col md={'11'}>
                                                    <input placeholder={'Write your query here'}
                                                           id={'prompt_input'}
                                                           onKeyDown={(e) => {
                                                               if (e.key === "Enter") {
                                                                   this.sendPrompt(e)
                                                               }
                                                           }}
                                                           style={{
                                                               outline: 'none',
                                                               border: 'none',
                                                               width: '100%',
                                                               color: 'white',
                                                               background: '#171717', height: '50px'
                                                           }}/>
                                                </Col>
                                                <Col md={1}
                                                     className={'d-flex justify-content-center align-items-center'}>
                                                    <HiOutlineArrowLongRight
                                                        onClick={(e) => this.sendPrompt(e)}
                                                        style={{
                                                            color: 'white',
                                                            background: 'green',
                                                            width: '30px',
                                                            cursor: 'pointer',
                                                            height: '30px'
                                                        }}/>
                                                </Col>
                                            </Row>


                                        </div>
                                    </InputToolbox>


                                </ChatContainer>

                            </MainContainer>
                        </div>

                    </Row>


                </div>
            </Container>

        );

    }


    getChatRoomDesktopMsgList(index, item){

        return(
            <div className={'p-4'}>
                <div>
                    <Message model={{
                        message: item.question,
                        direction: "outgoing",
                        position: "single"
                    }}>
                        <span className={'mb-2'} style={{
                            color: 'white',
                            marginRight: '10px'
                        }}>{this.state.profile.first_name + ' ' + this.state.profile.last_name}</span>
                        <Avatar {...this.stringAvatar(this.state.profile.first_name + ' ' + this.state.profile.last_name)} />
                    </Message>
                </div>
                <div>

                    <Message model={{
                        // message: item.completion,
                        direction: "incoming",
                        type: "custom",
                        position: "single"
                    }}>
                        <Avatar src='/logo192.png' name={'Vect'}/>
                        <Message.CustomContent>


                            <Markdown remarkPlugins={[remarkGfm]}>{item.completion}</Markdown>
                            {/*<Markdown remarkPlugins={[remarkGfm]}>{markdown}</Markdown>*/}


                        </Message.CustomContent>


                    </Message>
                </div>
            </div>
        );

    }


    sendPrompt(e){
        var prompt = document.getElementById('prompt_input').value;
        document.getElementById('prompt_input').value = '';
        this.sendPromptReq(prompt)
    }

    sendPromptReq(prompt){

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch(`http://45.76.142.28/api/v1/query?q=${prompt}&project_name=${this.state.data.project_name}&project_id=${this.state.data.project_id}`, requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {

                this.setState({prompt_id: response.id, is_waiting: true, last_prompt: prompt});
                this.startInterval(response.id)

            })
            .catch((error) => {
                localStorage.removeItem('token');
                window.location.href = '/login';

            });
    }

    async callSecondApi(id) {
        try {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                }

            };
            const params = new URLSearchParams({
                id: id
            }).toString();
            // Replace with your second API endpoint
            const response = await fetch(`http://45.76.142.28/api/v1/enquiry-response/?${params}`, requestOptions);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            this.setState({completions: data, is_waiting: false});
            clearInterval(this.state.intervalId);

        } catch (error) {
            // toast.error('An error occurred while calling the second API. Please try again.', {
            //     position: 'top-left'
            // });
        }
    }

    startInterval(id) {
        // Start the interval for repeated API calls
        const intervalId = setInterval(async () => {
            // Call the second API here
            await this.callSecondApi(id);
        }, this.intervalDuration);

        // Store the interval ID in the state to clear it later if needed
        this.setState({ intervalId: intervalId });
    }

}

export default TechnicalQueryChat;
