import React, {Component} from "react";
import AuthenticationHeader from "./authentication_header";
import Container from "react-bootstrap/Container";
import {Button, Card, Image, Row, Table} from "react-bootstrap";
import Footer from "./footer";
import {toast, ToastContainer} from "react-toastify";


class Login extends Component {

    constructor(props) {
        super(props);


        this.state = {
            email_address: null,
            password: null
        }

        this.inputRefs = {
            password: React.createRef(),
            email_address: React.createRef()
        };
    }

    componentDidMount() {
        if (localStorage.getItem('token') !== null) {
            window.location.href = '/panel';
        }
    }

    render() {
        return (
            <Container fluid className="p-0" style={{ height: '100%', backgroundColor: '#0F0F0F' }}>
                <ToastContainer/>
                <AuthenticationHeader/>
                <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
                    <Card style={{width: '40rem', backgroundColor: '#171717'}}>
                        <Card.Body>
                            <Row className="d-flex justify-content-center">
                                <Image
                                    src="/logo192.png"
                                    fluid
                                    className="d-inline-block align-top mt-5"
                                    alt="Vect AI "
                                    style={{ width: '100px' }}
                                />
                            </Row>
                            <Row>
                                <div className={'justify-content-center align-items-center d-flex mt-3'}
                                     style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                                    Login to our portal
                                </div>
                            </Row>
                            <Row className="d-flex justify-content-center align-items-center mt-4 mb-4">
                                <Table size="sm" style={{border: 'none', background: 'none', width: '30rem'}}>

                                    <tbody style={{background: 'none'}}>

                                    <tr>
                                        <td style={{border: 'none', backgroundColor: 'transparent'}}>
                                            <input type='email' placeholder='Email Address' required={true}
                                                   className={'p-3'}
                                                   ref={this.inputRefs.email_address}
                                                   style={{
                                                       color: 'white',
                                                       background: '#D9D9D91A',
                                                       border: 0,
                                                       width: '100%',
                                                       height: '3.1rem'
                                                   }}/>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{border: 'none', backgroundColor: 'transparent'}}>
                                            <input type='password' placeholder='Password' required={true}
                                                   className={'p-3'}
                                                   ref={this.inputRefs.password}
                                                   style={{
                                                       color: 'white',
                                                       background: '#D9D9D91A',
                                                       border: 0,
                                                       width: '100%',
                                                       height: '3.1rem'
                                                   }}/>
                                            <div className={'justify-content-center align-items-center d-flex mt-3'}
                                                 style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                                                Forget Password? <a href='/reset-password' style={{color: 'white'}}> Click Here</a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{border: 'none', backgroundColor: 'transparent'}}>
                                            <div className={'justify-content-center d-flex mt-4'}>
                                                <Button
                                                    onClick={(e) => this.handleSubmit(e)}
                                                    style={{
                                                        borderRadius: 0,
                                                        background: '#0E5B22',
                                                        border: "none",
                                                        width: '100%'
                                                    }}>Login</Button>
                                            </div>

                                            <div className={'justify-content-center align-items-center d-flex mt-3'}
                                                 style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                                                New Here? <a href={'/register'} style={{color: 'white'}}>Register Today</a>
                                            </div>

                                        </td>
                                    </tr>

                                    </tbody>
                                </Table>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
                <Footer/>
            </Container>
        );
    }

    handleSubmit = (e) => {
        // Access the value of the firstname input field

        const passwordValue = this.inputRefs.password.current.value;
        const emailValue = this.inputRefs.email_address.current.value;

        if (passwordValue === null || emailValue === null) {

            toast.error('Oops! Looks like you missed a field. Please double-check all fields.', {
                position: 'top-left'
            });

        } else {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    password: passwordValue,
                    username: emailValue
                })
            };

            fetch('http://45.76.142.28/api/login/', requestOptions)
                .then((response) => {
                    if (!response.ok) throw response.status;
                    else return response.json();
                })
                .then((response) => {
                    if(response.access !== undefined) {
                        localStorage.setItem('token', response.access);
                        window.location.href = '/panel'
                    }
                })
                .catch((error) => {
                    if (error === 200) {
                        window.location.href = '/panel'
                    }else {
                        if(error === 429) {
                            toast.error('You\'ve hit the limit for requests. Please wait 10 minutes before trying again.', {
                                position: 'top-left'
                            });
                        }else {
                            toast.error('Oops! Looks like you missed a field. Please double-check all fields.', {
                                position: 'top-left'
                            });
                        }
                    }
                });
        }

        // You can perform other operations like focusing inputs
        this.inputRefs.password.current.focus();
    }

}

export default Login;