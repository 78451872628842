import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";



class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            page_name: props.page
        }
    }

    render() {
        return (
            <Navbar style={{backgroundColor: '#171717'}} expand="lg" >
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            src="/logo192.png"
                            height="30"
                            className="d-inline-block align-top"
                            alt="Vect AI "
                        />
                    </Navbar.Brand>

                    <Navbar.Collapse className={"justify-content-end"}>
                        <Nav >
                            {/*<Nav.Link href="/policy" style={{color: 'white'}}>Privacy Policy</Nav.Link>*/}
                            {/*<Nav.Link href="/terms" style={{color: 'white'}}>Terms & Condition</Nav.Link>*/}
                        </Nav>
                        <Navbar.Text style={{color: 'white', marginLeft: '4rem'}}>Copyright@VECT AI 2024</Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default Footer;