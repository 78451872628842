import React, {Component} from "react";
import {Menu, menuClasses, MenuItem, Sidebar} from "react-pro-sidebar";
import {Link} from "react-router-dom";
import {Card, Image, Row} from "react-bootstrap";
import {MdOutlineDashboard, MdOutlinePrivacyTip, MdQueryStats} from "react-icons/md";
import {SiGooglebigquery} from "react-icons/si";
import {GrProjects} from "react-icons/gr";
import {FaQuora} from "react-icons/fa6";
import {IoIosHelpCircleOutline} from "react-icons/io";
import {CgProfile} from "react-icons/cg";
import {CiLogout} from "react-icons/ci";
import './side.css'

class SideNavbar extends Component{

    constructor(props) {
        super(props);
        this.state = {
            page_name: this.props.page_name
        }
    }


    render() {

        return(
            <div
                style={{
                    display: 'flex',
                    height: '100%',
                    minHeight: '400px',
                    position: 'fixed',
                    zIndex: '1',
                    top: '0',
                    left: '0',
                    width: '300px',
                    overflow: 'hidden',  // Disable scrolling within the div
                }} className={'text-start'}>
                <Card className={'shadow-6-strong'}>
                    <Sidebar style={{height:"100vh"}} backgroundColor="#171717">
                        <Menu  rootStyles={{
                            ['.' + menuClasses.button]: {
                                color: '#0E5B224D',
                                '&:hover': {
                                    backgroundColor: '#0E5B224D',
                                },
                            },
                        }} >
                            <div style={{marginBottom: '50px'}}>
                                <Row>
                                    <div className={"text-start"} style={{marginLeft: '2rem'}}>

                                        <Image
                                            src="/logo192.png"
                                            fluid
                                            className="d-inline-block align-top mt-5"
                                            alt="Vect AI "
                                            style={{ width: '90px' }}
                                        />
                                    </div>
                                </Row>
                            </div>

                            {this.getMenuItems()}

                        </Menu>

                    </Sidebar >
                </Card>
            </div>
        )

    }

    getMenuItems() {
        if(this.state.page_name === 'Dashboard'){
            return (
                <div>

                    <MenuItem rootStyles={{
                        ['.' + menuClasses.button]: {
                            backgroundColor: '#0E5B224D',
                            color: '#0E5B224D',
                            '&:hover': {
                                backgroundColor: '#0E5B224D',
                            },
                        },
                    }} icon={<MdOutlineDashboard style={{color: 'white'}}/>}
                              component={<Link to="/panel" style={{color: 'white', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Dashboard </MenuItem>
                    <MenuItem icon={<SiGooglebigquery style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/query-tool" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Query Tool</MenuItem>
                    <MenuItem icon={<MdQueryStats style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/technical-query" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Technical Query</MenuItem>
                    <MenuItem icon={<GrProjects style={{color: '#5A5C5F'}}/>}
                              component={<Link to={"/projects"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Projects</MenuItem>
                    <hr style={{color: '#6a686a', height: '13px'}}/>

                    <MenuItem icon={<FaQuora style={{color: '#5A5C5F'}}/>}
                              component={<Link to={"/faq"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>FAQs</MenuItem>
                    <MenuItem icon={<IoIosHelpCircleOutline style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/user-guideline" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>User Guide</MenuItem>
                    <MenuItem icon={<MdOutlinePrivacyTip style={{color: '#5A5C5F'}}/>}
                              component={<Link to={"/privacy"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Privacy & Policy</MenuItem>

                    <div className={'mt-lg-5'}></div>
                    <div className={'mt-lg-5'}></div>
                    <hr style={{color: '#6a686a', height: '13px'}}/>
                    <MenuItem icon={<CgProfile style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/user-profile" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Profile Settings</MenuItem>

                    <MenuItem onClick={()=>{localStorage.removeItem('token'); window.location.href = '/';}} icon={<CiLogout style={{color: '#5A5C5F'}}/>}
                              component={<span  style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Log Out</MenuItem>
                    <div className={'mt-lg-5'}></div>

                    {/*<MenuItem component={<Link to="/"/>}>{this.props.i18n.t('home_page')}</MenuItem>*/}
                    {/*<MenuItem component={<Link to="/logout"/>}>{this.props.i18n.t('sign_out')}<FaSignOutAlt style={{marginLeft: '10px'}}/></MenuItem>*/}
                </div>
            )
        }
        if (this.state.page_name === 'Projects') {
            return (
                <div>

                    <MenuItem icon={<MdOutlineDashboard style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/panel" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Dashboard </MenuItem>
                    <MenuItem icon={<SiGooglebigquery style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/query-tool" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Query Tool</MenuItem>
                    <MenuItem icon={<MdQueryStats style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/technical-query" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Technical Query</MenuItem>
                    <MenuItem rootStyles={{
                        ['.' + menuClasses.button]: {
                            backgroundColor: '#0E5B224D',
                            color: '#0E5B224D',
                            '&:hover': {
                                backgroundColor: '#0E5B224D',
                            },
                        },
                    }}
                              icon={<GrProjects style={{color: 'white'}}/>}
                              component={<Link to={"/projects"} style={{color: 'white', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Projects</MenuItem>
                    <hr style={{color: '#6a686a', height: '13px'}}/>

                    <MenuItem icon={<FaQuora style={{color: '#5A5C5F'}}/>}
                              component={<Link to={"/faq"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>FAQs</MenuItem>
                    <MenuItem icon={<IoIosHelpCircleOutline style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/user-guideline" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>User Guide</MenuItem>
                    <MenuItem icon={<MdOutlinePrivacyTip style={{color: '#5A5C5F'}}/>}
                              component={<Link to={"/privacy"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Privacy & Policy</MenuItem>

                    <div className={'mt-lg-5'}></div>
                    <div className={'mt-lg-5'}></div>
                    <hr style={{color: '#6a686a', height: '13px'}}/>
                    <MenuItem icon={<CgProfile style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/user-profile" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Profile Settings</MenuItem>

                    <MenuItem onClick={()=>{localStorage.removeItem('token'); window.location.href = '/';}} icon={<CiLogout style={{color: '#5A5C5F'}}/>}
                              component={<span  style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Log Out</MenuItem>
                    <div className={'mt-lg-5'}></div>

                    {/*<MenuItem component={<Link to="/"/>}>{this.props.i18n.t('home_page')}</MenuItem>*/}
                    {/*<MenuItem component={<Link to="/logout"/>}>{this.props.i18n.t('sign_out')}<FaSignOutAlt style={{marginLeft: '10px'}}/></MenuItem>*/}
                </div>
            )

        }
        if (this.state.page_name === 'QueryTool') {
            return (
                <div>

                    <MenuItem icon={<MdOutlineDashboard style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/panel" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Dashboard </MenuItem>
                    <MenuItem
                        rootStyles={{
                            ['.' + menuClasses.button]: {
                                backgroundColor: '#0E5B224D',
                                color: '#0E5B224D',
                                '&:hover': {
                                    backgroundColor: '#0E5B224D',
                                },
                            },
                        }} icon={<SiGooglebigquery style={{color: 'white'}}/>}
                        component={<Link to="/query-tool" style={{color: 'white', fontWeight: 'bold'}}
                                         className={'text-start'}/>}>Query Tool</MenuItem>
                    <MenuItem icon={<MdQueryStats style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/technical-query" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Technical Query</MenuItem>
                    <MenuItem
                        icon={<GrProjects style={{color: '#5A5C5F'}}/>}
                        component={<Link to={"/projects"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                         className={'text-start'}/>}>Projects</MenuItem>
                    <hr style={{color: '#6a686a', height: '13px'}}/>

                    <MenuItem icon={<FaQuora style={{color: '#5A5C5F'}}/>}
                              component={<Link to={"/faq"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>FAQs</MenuItem>
                    <MenuItem icon={<IoIosHelpCircleOutline style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/user-guideline" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>User Guide</MenuItem>
                    <MenuItem icon={<MdOutlinePrivacyTip style={{color: '#5A5C5F'}}/>}
                              component={<Link to={"/privacy"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Privacy & Policy</MenuItem>

                    <div className={'mt-lg-5'}></div>
                    <div className={'mt-lg-5'}></div>
                    <hr style={{color: '#6a686a', height: '13px'}}/>
                    <MenuItem icon={<CgProfile style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/user-profile" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Profile Settings</MenuItem>

                    <MenuItem onClick={()=>{localStorage.removeItem('token'); window.location.href = '/';}} icon={<CiLogout style={{color: '#5A5C5F'}}/>}
                              component={<span  style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Log Out</MenuItem>
                    <div className={'mt-lg-5'}></div>

                    {/*<MenuItem component={<Link to="/"/>}>{this.props.i18n.t('home_page')}</MenuItem>*/}
                    {/*<MenuItem component={<Link to="/logout"/>}>{this.props.i18n.t('sign_out')}<FaSignOutAlt style={{marginLeft: '10px'}}/></MenuItem>*/}
                </div>
            )
        }

        if(this.state.page_name === 'FAQ'){
            return (
                <div>

                    <MenuItem icon={<MdOutlineDashboard style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/panel" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Dashboard </MenuItem>
                    <MenuItem icon={<SiGooglebigquery style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/query-tool" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Query Tool</MenuItem>
                    <MenuItem icon={<MdQueryStats style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/technical-query" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Technical Query</MenuItem>
                    <MenuItem icon={<GrProjects style={{color: '#5A5C5F'}}/>}
                              component={<Link to={"/projects"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Projects</MenuItem>
                    <hr style={{color: '#6a686a', height: '13px'}}/>

                    <MenuItem  rootStyles={{
                        ['.' + menuClasses.button]: {
                            backgroundColor: '#0E5B224D',
                            color: '#0E5B224D',
                            '&:hover': {
                                backgroundColor: '#0E5B224D',
                            },
                        },
                    }}
                        icon={<FaQuora style={{color: 'white'}}/>}
                              component={<Link to={"/faq"} style={{color: 'white', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>FAQs</MenuItem>
                    <MenuItem icon={<IoIosHelpCircleOutline style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/user-guideline" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>User Guide</MenuItem>
                    <MenuItem icon={<MdOutlinePrivacyTip style={{color: '#5A5C5F'}}/>}
                              component={<Link to={"/privacy"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Privacy & Policy</MenuItem>

                    <div className={'mt-lg-5'}></div>
                    <div className={'mt-lg-5'}></div>
                    <hr style={{color: '#6a686a', height: '13px'}}/>
                    <MenuItem icon={<CgProfile style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/user-profile" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Profile Settings</MenuItem>

                    <MenuItem onClick={()=>{localStorage.removeItem('token'); window.location.href = '/';}} icon={<CiLogout style={{color: '#5A5C5F'}}/>}
                              component={<span  style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Log Out</MenuItem>
                    <div className={'mt-lg-5'}></div>

                    {/*<MenuItem component={<Link to="/"/>}>{this.props.i18n.t('home_page')}</MenuItem>*/}
                    {/*<MenuItem component={<Link to="/logout"/>}>{this.props.i18n.t('sign_out')}<FaSignOutAlt style={{marginLeft: '10px'}}/></MenuItem>*/}
                </div>
            )
        }

        if(this.state.page_name === 'PRIVACY'){
            return (
                <div>

                    <MenuItem icon={<MdOutlineDashboard style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/panel" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Dashboard </MenuItem>
                    <MenuItem icon={<SiGooglebigquery style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/query-tool" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Query Tool</MenuItem>
                    <MenuItem icon={<MdQueryStats style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/technical-query" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Technical Query</MenuItem>
                    <MenuItem icon={<GrProjects style={{color: '#5A5C5F'}}/>}
                              component={<Link to={"/projects"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Projects</MenuItem>
                    <hr style={{color: '#6a686a', height: '13px'}}/>

                    <MenuItem
                        icon={<FaQuora style={{color: '#5A5C5F'}}/>}
                              component={<Link to={"/faq"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>FAQs</MenuItem>
                    <MenuItem icon={<IoIosHelpCircleOutline style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/user-guideline" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>User Guide</MenuItem>
                    <MenuItem  rootStyles={{
                        ['.' + menuClasses.button]: {
                            backgroundColor: '#0E5B224D',
                            color: '#0E5B224D',
                            '&:hover': {
                                backgroundColor: '#0E5B224D',
                            },
                        },
                    }}
                               icon={<MdOutlinePrivacyTip style={{color: 'white'}}/>}
                              component={<Link to={"/privacy"} style={{color: 'white', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Privacy & Policy</MenuItem>

                    <div className={'mt-lg-5'}></div>
                    <div className={'mt-lg-5'}></div>
                    <hr style={{color: '#6a686a', height: '13px'}}/>
                    <MenuItem icon={<CgProfile style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/user-profile" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Profile Settings</MenuItem>

                    <MenuItem onClick={()=>{localStorage.removeItem('token'); window.location.href = '/';}} icon={<CiLogout style={{color: '#5A5C5F'}}/>}
                              component={<span  style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Log Out</MenuItem>
                    <div className={'mt-lg-5'}></div>

                    {/*<MenuItem component={<Link to="/"/>}>{this.props.i18n.t('home_page')}</MenuItem>*/}
                    {/*<MenuItem component={<Link to="/logout"/>}>{this.props.i18n.t('sign_out')}<FaSignOutAlt style={{marginLeft: '10px'}}/></MenuItem>*/}
                </div>
            )
        }

        if(this.state.page_name === 'UserGuideline'){
            return (
                <div>

                    <MenuItem icon={<MdOutlineDashboard style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/panel" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Dashboard </MenuItem>
                    <MenuItem icon={<SiGooglebigquery style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/query-tool" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Query Tool</MenuItem>
                    <MenuItem icon={<MdQueryStats style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/technical-query" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Technical Query</MenuItem>
                    <MenuItem icon={<GrProjects style={{color: '#5A5C5F'}}/>}
                              component={<Link to={"/projects"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Projects</MenuItem>
                    <hr style={{color: '#6a686a', height: '13px'}}/>

                    <MenuItem
                        icon={<FaQuora style={{color: '#5A5C5F'}}/>}
                              component={<Link to={"/faq"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>FAQs</MenuItem>
                    <MenuItem rootStyles={{
                        ['.' + menuClasses.button]: {
                            backgroundColor: '#0E5B224D',
                            color: '#0E5B224D',
                            '&:hover': {
                                backgroundColor: '#0E5B224D',
                            },
                        },
                    }} icon={<IoIosHelpCircleOutline style={{color: 'white'}}/>}
                              component={<Link to="/user-guideline" style={{color: 'white', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>User Guide</MenuItem>
                    <MenuItem
                               icon={<MdOutlinePrivacyTip style={{color: '#5A5C5F'}}/>}
                              component={<Link to={"/privacy"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Privacy & Policy</MenuItem>

                    <div className={'mt-lg-5'}></div>
                    <div className={'mt-lg-5'}></div>
                    <hr style={{color: '#6a686a', height: '13px'}}/>
                    <MenuItem icon={<CgProfile style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/user-profile" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Profile Settings</MenuItem>

                    <MenuItem onClick={()=>{localStorage.removeItem('token'); window.location.href = '/';}} icon={<CiLogout style={{color: '#5A5C5F'}}/>}
                              component={<span  style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Log Out</MenuItem>
                    <div className={'mt-lg-5'}></div>

                </div>
            )
        }

        if(this.state.page_name === 'TechnicalQuery'){
            return (
                <div>

                    <MenuItem icon={<MdOutlineDashboard style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/panel" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Dashboard </MenuItem>
                    <MenuItem icon={<SiGooglebigquery style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/query-tool" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Query Tool</MenuItem>
                    <MenuItem rootStyles={{
                        ['.' + menuClasses.button]: {
                            backgroundColor: '#0E5B224D',
                            color: '#0E5B224D',
                            '&:hover': {
                                backgroundColor: '#0E5B224D',
                            },
                        },
                    }} icon={<MdQueryStats style={{color: 'white'}}/>}
                              component={<Link to="/technical-query" style={{color: 'white', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Technical Query</MenuItem>
                    <MenuItem icon={<GrProjects style={{color: '#5A5C5F'}}/>}
                              component={<Link to={"/projects"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Projects</MenuItem>
                    <hr style={{color: '#6a686a', height: '13px'}}/>

                    <MenuItem
                        icon={<FaQuora style={{color: '#5A5C5F'}}/>}
                        component={<Link to={"/faq"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                         className={'text-start'}/>}>FAQs</MenuItem>
                    <MenuItem  icon={<IoIosHelpCircleOutline style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/user-guideline" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>User Guide</MenuItem>
                    <MenuItem
                        icon={<MdOutlinePrivacyTip style={{color: '#5A5C5F'}}/>}
                        component={<Link to={"/privacy"} style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                         className={'text-start'}/>}>Privacy & Policy</MenuItem>

                    <div className={'mt-lg-5'}></div>
                    <div className={'mt-lg-5'}></div>
                    <hr style={{color: '#6a686a', height: '13px'}}/>
                    <MenuItem icon={<CgProfile style={{color: '#5A5C5F'}}/>}
                              component={<Link to="/user-profile" style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                               className={'text-start'}/>}>Profile Settings</MenuItem>

                    <MenuItem onClick={()=>{localStorage.removeItem('token'); window.location.href = '/';}} icon={<CiLogout style={{color: '#5A5C5F'}}/>}
                              component={<span  style={{color: '#5A5C5F', fontWeight: 'bold'}}
                                                className={'text-start'}/>}>Log Out</MenuItem>
                    <div className={'mt-lg-5'}></div>

                </div>
            )
        }


    }
}

export default SideNavbar;